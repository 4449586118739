<!--
   User: Liu Yin
   Date: 2020/3/23
   Description:企业学院列表组件
 -->
<template>
  <article class="appraise">
    <el-row :gutter="20" style="max-width: 1280px;margin: 0 auto;">
      <el-col v-for="(item, index) in records" :key="index" :xs="12" :sm="8" :md="6" :lg="6" :xl="6" class="item-container">
        <div style="position: relative">
          <div v-if="item.collation !== 0" class="keynote" :style="{
            backgroundImage: 'url(' + imageUrl(item.collation) + ')',
          }">
            <div class="collationfont">{{ convert(item.collation) }}</div>
          </div>
          <el-card :body-style="{ padding: '0px' }">
            <article class="item-parent" @click="openCourse(item)">
              <!-- <div class="item-top" :style="[getBackgroundStyle(item)]"></div> -->

              <div class="item-top">
                <img :src="getImg(item.cuUrl)" @error="imgError">
                <!-- <div v-if="item.collation!==0" class="keynote">{{ convert(item.collation) }}</div> -->

                <div class="masking">
                  <div v-if="item.cuHours" class="clickNum">
                    <span class="marright">{{ division(item.cuHours) }}</span>
                  </div>
                  <div v-if="item.clickCount" class="coureHours">
                    <i class="el-icon-view"></i><span class="marright">{{ item.clickCount }}</span>
                  </div>
                </div>
              </div>

              <div class="item-bottom">
                <div class="item-name" :title="item.cuName">
                  {{ item.cuName }}
                </div>
                <div v-if="!item.price && !item.discountedPrice" class="freebase">免费</div>
                <div v-else>
                  <span v-if="item.price && item.discountedPrice" class="pricecolor">￥{{ item.price }}</span>
                  <span v-if="item.discountedPrice" class="paycolor">￥{{ item.discountedPrice }}</span>
                  <span v-if="item.price && !item.discountedPrice" class="paycolor">￥{{ item.price }}</span>

                </div>
              </div>
              <p v-if="item.timeDivision" class="limitTime">限时： {{ item.timeDivision }}</p>
            </article>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <ul class="appraise-list">
      <li v-for="(item,index) in records" :key="index" class="item-container">
        <el-card :body-style="{ padding: '0px' }">
          <div v-if="item.collation!==0" class="parallelogram">
            <div>{{ convert(item.collation) }}</div>
          </div>
          <article class="item-parent" @click="openCourse(item)">
            <div class="item-top" :style="[getBackgroundStyle(item)]"></div>
            <div class="item-bottom" style="height: 90px">
              <h1 class="item-name">{{ item.cuName }}</h1>
              <p style="margin-top: 15px" class="item-content second-line">发布时间: {{ item.releaseTime | moment('YYYY-MM-DD HH:mm:ss') }} </p>
            </div>
          </article>
        </el-card>
      </li>
    </ul> -->

    <div style="text-align: center">
      <el-pagination class="pageAction" :current-page="currentPage" :page-size="12" :background="false" layout="prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange" />
    </div>
  </article>
</template>

<script>
import backgroundImage from '../../../../../assets/images/talent/appraise-top.png'
import { getUserInfo } from '../../../../../utils/auth'
export default {
  props: {
    records: {
      type: Array,
      default() {
        return []
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      courseList: [
        {
          title: 'HTML/HTML5基础',
          name: '0基础学前端---HTML/HTML5基础',
          speaker: '写代码的平面师',
          content: '课程大纲(8门课程|6个实战作业)',
          type: 1
        },
        {
          title: 'HTML/HTML5基础',
          name: '0基础学前端---HTML/HTML5基础',
          speaker: '写代码的平面师',
          content: '课程大纲(8门课程|6个实战作业)',
          type: 1
        },
        {
          title: 'HTML/HTML5基础',
          name: '0基础学前端---HTML/HTML5基础',
          speaker: '写代码的平面师',
          content: '课程大纲(8门课程|6个实战作业)',
          type: 1
        }
      ],
      user: getUserInfo() || {}
    }
  },
  updated() {
    setTimeout(this.countTime, 1000)
    // this.countTime()
  },
  // mounted() {
  //   setTimeout(this.countTime(), 1000)
  // },
  methods: {
    countTime() {
      this.records.forEach(item => {
        if (item.discountCountdown) {
          item.nowtime = new Date().getTime()
          item.endtime = new Date(item.discountCountdown).getTime()
          item.leftTime = item.endtime - item.nowtime
          // var d, h, m, s
          if (item.leftTime >= 0) {
            // d = Math.floor(item.leftTime / 1000 / 60 / 60 / 24)
            item.h = Math.floor(item.leftTime / 1000 / 60 / 60)
            item.m = Math.floor(item.leftTime / 1000 / 60 % 60)
            // s = Math.floor(item.leftTime / 1000 % 60)
          }
          console.log(item.nowtime, item.discountCountdown, item.endtime, item.leftTime)
          // 将倒计时赋值到div中
          // document.getElementById('_d').innerHTML = d + ':'
          // document.getElementById('_h').innerHTML = h + ':'
          // document.getElementById('_m').innerHTML = m + ':'
          if (item.leftTime >= 0) {
            item.timeDivision = item.h > 0 ? item.h + '小时' + item.m + '分' : item.m + '分'
            console.log(item.timeDivision, 'item.timeDivision')
            // document.getElementById('_s').innerHTML = s + '秒'
            // 递归每秒调用countTime方法，显示动态时间效果
            // this.countTime = setInterval(
            //   this.countTime,
            //   1000, // 每一秒调用一次
            //   item.endtime // 设置的结束时间
            // )
          } else {
            item.timeDivision = ''
          }

          this.$forceUpdate()
        }
      })
    },
    division(data) {
      if (data) {
        // const num3 = data / 3600
        // const num = (data / 3600 - parseInt(num3)) * 60
        const num = (data / 3600) * 60
        const num2 = data % 60
        // let Hour = ''
        let minute = ''
        let second = ''

        // if (parseInt(num3) !== 0) {
        //   Hour = parseInt(num3) + '小时'
        // }
        if (parseInt(num) !== 0) {
          minute = parseInt(num) + ':'
        }
        if (num2 !== 0) {
          second = num2
          // second = num2 + '秒'
        }
        // return Hour + minute + second
        return minute + second
      }
    },
    // 加默认封面
    getImg(item) {
      if (item) return item
      return backgroundImage
    },
    // 图片链接失效/错误给默认图
    imgError(event) {
      const img = event.srcElement
      img.src = backgroundImage
      img.onerror = null // 防止闪图
    },
    convert(data) {
      switch (data) {
        case 1:
          return '专业'
        case 2:
          return '特色'
        case 3:
          return '重点'
      }
    },
    imageUrl(data) {
      switch (data) {
        case 1:
          return require('../../../../../assets/images/keypoint.png')
        case 2:
          return require('../../../../../assets/images/characteristic.png')
        case 3:
          return require('../../../../../assets/images/major.png')
      }
    },
    handleCurrentChange(page) {
      this.$emit('changePage', page)
    },
    getBackgroundStyle(item) {
      if (!item.cuUrl) {
        return { backgroundImage: 'url(' + backgroundImage + ')' }
      }
      return { backgroundImage: 'url(' + item.cuUrl + ')' }
    },
    openCourse(item) {
      if (item.price || item.discountedPrice) {
        this.$message.error('请联系管理员观看课程!')
      } else {
        this.$emit('openCourse', item)
      }
    },
    // 添加到岗位
    handleCollect() {
      console.log('添加到岗位')
    },
    // 收藏
    handleAdd() { }
  }
}
</script>

<style lang="scss" scoped>
.appraise {
  // background-color: white;
  padding-bottom: 20px;
}
.appraise-list {
  display: flex;
  flex-wrap: wrap;
}
.item-container:hover {
  transition: all 0.5s ease;
  transform: translateY(-5%);
  .item-parent {
    .item-top {
      .masking::before {
        display: none;
      }
      overflow: hidden;
      .coureHours {
        display: none;
      }
      .clickNum {
        display: none;
      }
      img {
        transition: all 0.5s ease;
        transform: scale(1.1);
      }
    }
  }
}

.item-container {
  // width: 25%;
  // box-sizing: border-box;
  padding: 8px;
  min-height: 235px;

  .item-parent {
    cursor: pointer;
    transition: all 0.6s;
    min-height: 235px;
    .masking::before {
      content: "";
      // background-color: rgba(0,0,0,.5);
      background: linear-gradient(
        0deg,
        #000000d9,
        rgb(0 0 0 / 69%),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.17),
        hsla(0, 0%, 100%, 0)
      );
      width: 100%;
      height: 35px;
      position: absolute;
      bottom: 2px;
      left: 0;
    }
    .item-top {
      position: relative;
      height: 71.8%;
      width: 100%;

      img {
        background-image: url("../../../../../assets/images/appraise-top.png");
        background-size: 100% 100%;
        background-position: 50% 50%;
        width: 100%;
        height: 170px;
        overflow: hidden;
      }
    }
    .item-bottom {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3.5% 0 0 0;
      .label-parent {
        margin-top: 20px;
      }
      .item-name {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
        width: 230px;
      }
      .item-speaker {
      }
      .item-content {
        margin-top: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: rgb(135, 135, 135);
      }
      .label {
        background-color: rgb(231, 146, 71);
        padding: 3px 10px;
        color: white;
        font-size: 17px;
      }
    }
  }
}

.pageAction {
  margin-top: 30px;
}
::v-deep .el-pagination {
  button {
    background: initial;
    .el-icon {
      font-size: 17px;
    }
  }
  .number {
    background: initial;
    font-size: 16px;
  }
}
::v-deep .el-card {
  border-radius: 8px;
  // min-height: 235px;
  position: relative;
}
.parallelogram {
  /* 平行四边形 */
  width: 80px;
  height: 20px;
  background: #dfb576;
  transform: skew(-15deg);
  // margin-left: 2px;
  position: absolute;
  top: 0px;
  border-radius: 10px 0 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    transform: skew(15deg);
    color: #fff;
    font-size: 14px;
  }
}
.coureHours {
  position: absolute;
  bottom: 6px;
  left: 10px;

  color: #fff;
}
.clickNum {
  position: absolute;
  bottom: 6px;
  right: 10px;

  color: #fff;
}
.keynote {
  position: absolute;
  top: -10px;
  left: 10px;
  z-index: 9;
  color: #fff;
  width: 62px;
  height: 62px;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.marright {
  margin-left: 5px;
  font-size: 12px;
}
.freebase {
  color: #fff;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  height: 18px;
  line-height: 17px;
  border-radius: 2px;
  text-align: center;
  background-color: #fa9841;
  padding: 0 4.5px;
  min-width: 33px;
  box-sizing: border-box;
}
.paycolor {
  font-size: 16px;
  padding: 0px;
  margin-left: 5px;
  font-weight: 600;
  color: #c7000b;
  line-height: 18px;
  // float: right;
  background-color: rgba(223, 225, 230, 0);
}
.pricecolor {
  text-decoration: line-through;
  font-size: 12px;
  padding: 0px;
  margin-left: 10px;
  // color: #e6a23c;
  line-height: 18px;
  // float: right;
  background-color: rgba(223, 225, 230, 0);
}
.collationfont {
  margin-top: -7px;
}
.limitTime {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  margin: 0px 10px 0px 0;
  color: #ee0a24;
}
</style>
