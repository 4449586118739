<!--
   User: Liu Yin
   Date: 2020/5/22
   Description:课程目录
 -->
<template>
  <ul style="background: rgb(236,238,242);height: 100%;overflow: auto">
    <li v-for="(item,index) in menus" :key="index">
      <p class="catalog-chapter">{{ item.chName }}</p>
      <ul style="margin-left: 20px">
        <li v-for="(val,num) in item.courseVideoDtos" :key="num" class="video-item-hover">
          <span class="videoStyle" :class="[fileRelationId===val.viId?'focus-section':'not-focus-section']"><i class="el-icon-video-play marginright"></i>{{ val.viName }}</span>
        </li>
      </ul>
      <ul style="margin-left: 20px">
        <li v-for="(sectionItem,sectionIndex) in item.sections" :key="sectionIndex" class="catalog-section">
          <div class="catalog-section-container">
            <span>{{ sectionItem.seName }}</span>
            <ul style="margin-left: 20px">
              <li v-for="(videoItem,videoIndex) in sectionItem.courseVideoDtos" :key="videoIndex" class="video-item-hover">
                <span class="videoStyle" :class="[fileRelationId===videoItem.viId?'focus-section':'not-focus-section']"><i class="el-icon-video-play marginright"></i>{{ videoItem.viName }}</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>

export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {}
      }
    },
    isMine: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      menus: this.rowData.chapters,
      fileRelationId: 0
    }
  },
  watch: {
    rowData() {
      this.initMenus()
    }
  },
  mounted() {
    // this.initMenus()
  },
  methods: {
    initMenus() {
      this.menus = this.rowData.chapters
      this.playFirstVideo()
      // this.getCurrentSection()
    },
    playFirstVideo() {
      const firstChapter = this.rowData.chapters[0]
      if (!firstChapter) return
      const firstSection = firstChapter.sections[0]
      if (!firstSection) return
      this.playVideo(firstSection, firstChapter)
    },
    // getCurrentSection () {
    //   this.$get('/course/person/record/getNewRecord', {}, [this.rowData.cuId]).then(result => {
    //     if (result.code === 'SUCCESS' && result.data) {
    //       const chId = result.data.chId
    //       const csId = result.data.csId
    //       if (!this.menus) return
    //       const chapter = this.menus.find(item => {
    //         if (item.chId === chId) {
    //           return true
    //         }
    //       })
    //       if (chapter) {
    //         const sectionList = chapter.sections
    //         const section = sectionList.find(item => {
    //           if (item.seId === csId) {
    //             return true
    //           }
    //         })
    //         if (section) {
    //           this.playVideo(section, chapter)
    //         }
    //       }
    //     } else {
    //       this.playFirstVideo()
    //     }
    //   }).catch(e => {

    //   })
    // },
    playVideo(section, chapter) {
      // if (this.fileRelationId === section.viId) return
      if (chapter.courseVideoDtos && chapter.courseVideoDtos?.length > 0) {
        this.fileRelationId = chapter.courseVideoDtos[0].viId
      } else {
        if (section.courseVideoDtos?.length > 0) {
          this.fileRelationId = section.courseVideoDtos[0].viId
        }
      }
      // this.$forceUpdate()
      this.$emit('change', section, chapter)
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog-chapter {
  background: linear-gradient(
    to right,
    rgb(253, 199, 95) 0%,
    rgb(243, 164, 61) 100%
  );
  border-radius: 2px;
  line-height: px2vw(35);
  padding: 0 20px;
  color: white;
  font-weight: 500;
  display: inline-block;
  margin-bottom: px2vw(10);
  margin-top: px2vw(10);
}
.catalog-section {
  position: relative;
  line-height: px2vw(40);
  .catalog-section-container {
    cursor: pointer;
    display: inline-block;
    span {
      margin-left: px2vw(20);
      overflow: hidden;
      white-space: normal;
      word-wrap: break-word;
      word-break: break-all;
      text-overflow: ellipsis;
    }
  }
}
.catalog-section::before {
  content: "";
  position: absolute;
  left: 4px;
  top: 17px;
  border: 1px solid #2c6efb;
  background-color: #2c6efb;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 12px;
}
.videoStyle {
  font-size: 14px;
}
.marginright {
  margin-right: 5px;
}
.video-item-hover {
  // height: 40px;
  display: flex;
  align-items: center;
}
.video-item-hover :hover {
  color: rgb(253, 199, 95);
}
.focus-section {
  color: rgb(253, 199, 95);
}
.not-focus-section {
  cursor: not-allowed;
  color: #999;
}
</style>
