<!--
   User: Liu Yin
   Date: 2020/4/30
   Description:
 -->
<template>
  <article class="course-detail">
    <el-card class="box-card title-card">
      <div style="display: flex;align-items: center">
        <!-- <return-key @close="handleClose"></return-key> -->
        <el-breadcrumb separator-class="el-icon-arrow-right" class="curpoin">
          <el-breadcrumb-item><span class="curpoin" @click="handleClose()">企业学院</span></el-breadcrumb-item>
          <el-breadcrumb-item>{{ rowData.cuName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- <h3 style="margin-left: 20px"></h3> -->
      </div>
    </el-card>
    <el-card class="box-card course-content">
      <el-tabs v-model="activeName" style="margin: 0 10px">
        <el-tab-pane label="课程介绍" name="first">
          <div class="rich-text" style="margin-top: 15px;min-height: 605px;" v-html="rowData.cuSurvey"></div>
        </el-tab-pane>
        <el-tab-pane label="课程目录" name="second">
          <div class="course-video">
            <catalog v-loading="loading" class="catalog" :row-data="detailData" :is-mine="isMine" @change="playVideo"></catalog>
            <div class="video-container">
              <video v-show="videoUrl" id="video" controls="controls" controlsList="nodownload">
              </video>
              <div v-if="!videoUrl" style="text-align: center">
                <svg-icon icon-class="no-data" style="font-size: 100px"></svg-icon>
                <p>暂无视频</p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="课程附件" name="third">
          <template>
            <el-table :data="tableData" border fit style="width: 100%;">
              <el-table-column label="文件名称" prop="fileName" width="400">
              </el-table-column>
              <el-table-column label="文件路径" prop="filePath">
              </el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="downLoad(scope.$index, scope.row)">下载</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-tab-pane> -->
      </el-tabs>
    </el-card>

  </article>
</template>

<script>
import catalog from './components/detail/catalog'
import { downloadFile } from '@/utils/tools'
import { getUserInfo } from '../../../../utils/auth'
export default {
  components: {
    catalog
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {}
      }
    },
    isMine: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      activeName: 'second',
      videoUrl: null,
      detailData: {},
      myVideo: null,
      // 临时存储时间,超出上次观看时间禁止快进
      tempTime: 0,
      originMaxPoint: 0,
      maxPoint: 0,
      currentSection: {},
      cpId: null,
      tableData: [],
      userinfo: [],
      viewflag: false
    }
  },
  mounted() {
    document.body.scrollTop = 0
    this.getDetailData()
    this.initVideo()
    // this.listenWindow()
    this.userInfo = getUserInfo()
  },
  // beforeDestroy () {
  //   window.onbeforeunload = null
  //   window.removeEventListener('offline', this.saveVideoMessage)
  //   window.removeEventListener('online', this.uploadLocalstorageData)
  //   // this.uploadVideoInfo()
  // },
  methods: {
    handleClose() {
      console.log(1)
      this.$emit('close')
    },
    downLoad(index, row) {
      downloadFile(row.filePath, row.fileName)
    },
    initVideo() {
      var myVideo = document.getElementById('video')
      this.myVideo = myVideo
      // 监听播放开始
      myVideo.addEventListener('play', function () {
        console.log('开始播放')
      })

      // 监听播放结束
      myVideo.addEventListener('pause', () => {
        console.log('播放暂停')
        if (this.viewflag === true && myVideo.currentTime >= 300) {
          if (this.userInfo) {
            this.$alert('试看已结束,请进入后台管理观看', null, {
              confirmButtonText: '确定'
            })
          } else {
            this.$alert('试看已结束,请您登录观看', null, {
              confirmButtonText: '确定'
            })
          }
        }
        // this.uploadVideoInfo()
      })

      // 监听播放结束
      myVideo.addEventListener('ended', () => {
        console.log('播放结束')
        if (this.tempTime < 300) {
          if (this.userInfo) {
            this.$alert('试看已结束,请进入后台管理观看', null, {
              confirmButtonText: '确定'
            })
          } else {
            this.$alert('试看已结束,请您登录观看', null, {
              confirmButtonText: '确定'
            })
          }
        }
        // this.uploadVideoInfo()
      })
      // 使用事件监听方式捕捉事件， 此事件可作为实时监测video 播放状态
      myVideo.addEventListener('timeupdate', () => {
        const currentTime = myVideo.currentTime

        if (currentTime > this.maxPoint) {
          var time = myVideo.currentTime
          if (time - this.tempTime > 0.5) {
            myVideo.currentTime = this.tempTime
          } else {
            this.maxPoint = currentTime
          }
        }
        this.tempTime = myVideo.currentTime
        // 试看5分钟后暂停
        if (myVideo.currentTime >= 300) {
          this.myVideo.pause()
          this.viewflag = true
        }
      }, false)
    },

    // uploadVideoInfo () {
    //   if (this.myVideo.currentTime > 0) {
    //     const params = this.getVideoMessage()
    //     this.$post('/course/person/record/save', params).then(result => {
    //       if (result.code === 'SUCCESS') {
    //         this.originMaxPoint = this.myVideo.currentTime
    //       } else {
    //         console.error('视频信息更新失败')
    //       }
    //     }).catch(e => {
    //       console.error('视频信息更新失败')
    //     })
    //   }
    // },

    getVideoMessage() {
      const currentTime = this.myVideo.currentTime
      const duration = this.myVideo.duration

      const maxPoint = currentTime > this.originMaxPoint ? currentTime : this.originMaxPoint

      const ratio = maxPoint / duration
      const formatRatio = Math.round(ratio * 100) / 100
      const section = this.currentSection
      return {
        chId: section.chId,
        cuId: section.cuId,
        csId: section.seId,
        cpRatio: formatRatio,
        maxPoint: maxPoint,
        videoTime: duration,
        nowPoint: currentTime,
        cpId: this.cpId
      }
    },

    // listenWindow () {
    //   var a = '注意！！\n您即将离开页面！离开后可能会导致数据丢失\n\n您确定要离开吗？'
    //   window.onbeforeunload = function (b) {
    //     // this.uploadVideoInfo()
    //     b = b || window.event
    //     b.returnValue = a
    //     return a
    //   }
    //   window.addEventListener('offline', this.saveVideoMessage, false)
    //   window.addEventListener('online', this.uploadLocalstorageData, false)
    // },
    // 保存视频播放信息
    // saveVideoMessage () {
    //   const videoMessage = JSON.stringify(this.getVideoMessage())
    //   localStorage.setItem('videoMessage', videoMessage)
    // },
    // uploadLocalstorageData () {
    //   const message = localStorage.getItem('videoMessage')
    //   if (message) {
    //     this.$post(this.urls.courseVideoSave, JSON.parse(message)).then(result => {
    //       if (result.code === 'SUCCESS') {
    //         localStorage.removeItem('videoMessage')
    //       } else {
    //         console.error('视频信息更新失败')
    //       }
    //     }).catch(e => {
    //       console.error('视频信息更新失败')
    //     })
    //   }
    // },

    getDetailData() {
      this.loading = true
      this.$get(this.urls.getCourseId, {}, [this.rowData.cuId]).then(result => {
        if (result.code === 'SUCCESS') {
          this.loading = false
          const data = result.data
          this.detailData = data
          this.tableData = result.data.courseFileRelations
        } else {
          this.loading = false
          this.$message.error(result.msg)
        }
      }).catch(() => {
      })
    },
    playVideo(section, chapter) {
      if (this.isMine) {
        // this.getVideoRecordTime(item)
        // this.saveCoursePerson(item, chapter)
      }
      console.log(section, 'section')
      if (chapter.courseVideoDtos?.length > 0 && chapter.courseVideoDtos[0].viUrl != null && chapter.courseVideoDtos[0].viUrl !== undefined) {
        this.videoUrl = chapter.courseVideoDtos[0].viUrl
        document.querySelector('#video').src = this.videoUrl
      } else if (section.courseVideoDtos?.length > 0 && section.courseVideoDtos[0].viUrl != null && section.courseVideoDtos[0].viUrl !== undefined) {
        this.videoUrl = section.courseVideoDtos[0].viUrl
        document.querySelector('#video').src = this.videoUrl
        // this.getVideo(item.viUrl)
      }
      // this.currentSection = item
      // const url = item.seUrl
      // this.videoUrl = url
      // if (url) {
      //   $("#video").bind('contextmenu', function () {
      //     return false;
      //   })
      //   document.querySelector('#video').src = url
      //   this.tempTime = 0
      // }

      // let v = document.getElementById("video");   //#video是video标签
      // let blob = this.response;   //服务器返回二进制流
      // v.src = (URL || webkitURL).createObjectURL(blob);  //使用createObjectURL方法把二进制流当作url使用
    }
    // getVideoRecordTime (section) {
    //   const params = {
    //     chId: section.chId,
    //     cuId: section.cuId,
    //     csId: section.seId
    //   }
    //   this.$get('/course/person/record/getRecord', params).then(result => {
    //     if (result.code === 'SUCCESS' && result.data) {
    //       if (result.data) {
    //         if (result.data.nowPoint) {
    //           this.myVideo.currentTime = result.data.nowPoint
    //         } else {
    //           this.myVideo.currentTime = 0
    //         }
    //         if (result.data.maxPoint) {
    //           this.maxPoint = result.data.maxPoint
    //           this.originMaxPoint = result.data.maxPoint
    //         } else {
    //           this.maxPoint = 0
    //           this.originMaxPoint = 0
    //         }
    //         this.cpId = result.data.cpId
    //       }
    //     } else {
    //       this.cpId = null
    //       this.myVideo.currentTime = 0
    //       this.maxPoint = 0
    //     }
    //   }).catch(e => {
    //     console.error(e)
    //   })
    // },
    // saveCoursePerson (section, chapter) {
    //   const params = {
    //     chId: section.chId,
    //     cuId: section.cuId,
    //     csId: section.seId,
    //     csName: section.seName,
    //     chName: chapter.chName
    //   }
    //   this.$post('/course/person/save', params).then(result => {
    //     // eslint-disable-next-line no-empty
    //     if (result.code === 'SUCCESS' && result.data) {
    //     }
    //   }).catch(e => {
    //     console.error(e)
    //   })
    // }
  }
}
</script>

<style scoped lang="scss">
.course-detail {
  background: rgb(236, 238, 242);
  padding: 1px 20px 80px 10px;
  .box-card {
    margin-left: px2vw(20);
    margin-right: px2vw(30);
    height: auto;
    ::v-deep .el-tabs__item {
      font-size: px2vw(21);
    }
    ::v-deep .el-tabs__active-bar {
      width: 80px;
    }
  }
  .title-card {
    margin-top: 5px;
    ::v-deep .el-card__body {
      padding: 12px 20px;
    }
  }
}
.course-content {
  height: 750px;
  margin-top: px2vw(13);
  .course-video {
    display: flex;
    height: 620px;
    .catalog {
      width: 30%;
      height: 100%;
    }
    .video-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #video {
      height: 100%;
      width: 100%;
    }
  }
}
.curpoin {
  cursor: pointer;
}
</style>
